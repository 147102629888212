<template>
  <div class="addInstallationQuestion">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="questions">
        <div class="row mb-4">
          <div class="col-12 mb-4">
            <div class="cell">
              <label>{{ $t('language') }}</label>
              <LanguageSelect
                :class="['m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Language') }]"
                :used-languages="usedLanguages"
                :language="currentLanguage"
                @selectedLanguage="updateLanguage"
              />
              <span
                v-show="errors.has('Language')"
                class="badge badge-danger"
              >{{ errors.first('Language') }}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="cell">
              <label>{{ $t('question') }}</label>
              <textarea
                ref="questionArea"
                v-model="question"
                :class="['form-control question-area m-input m-input--air', {'is-invalid': this.$validator.errors.has('Question') || this.$validator.errors.has('Macro') }]"
                placeholder="Enter a question to be added"
                type="text"
                rows="8"
              />
              <span
                v-show="errors.has('Question')"
                class="badge badge-danger"
              >{{ errors.first('Question') }}</span>
              <span
                v-show="errors.has('Macro')"
                class="badge badge-danger"
              >{{ errors.first('Macro') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="cell">
              <h5>{{ 'Placeholders' }}</h5>
              <h6>{{ $t('common') }}</h6>
              <div class="row">
                <button
                  class="btn btn-secondary"
                  @click="addPlaceholder('{installation_name}')"
                >
                  {{ $t('installationName') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="cell">
              <button
                :disabled="canAddElementBool"
                class="btn btn-primary float-right"
                @click="addQuestion()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="plus"
                />
                <span>
                  {{ $t('add') }}
                </span>
              </button>
              <div class="clearfix" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'AddInstallationQuestion',
  components: {
    LanguageSelect: () => import('@/components/SmsManagement/LanguageSelect.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      template: null,
      question: null,
      questions: null,
      subject: null,
      currentLanguage: null,
      selectedArea: null,
      usedLanguages: []
    }
  },
  computed: {
    canAddElementBool: function () {
      if (this.templateId && this.question) return false;
      return true;
    }
  },
  created () {
    this.getAllQuestions();
  },  
  methods: {
    addPlaceholder (val) {
      if (this.question == null) {
          this.question = "";
        }
        var questionArea = this.$refs.questionArea;
        var questionPosition = questionArea.selectionStart
        var msg = this.question;
        this.question = msg.substring(0, questionPosition) + val + msg.substring(questionPosition);
        questionArea.focus();
    },
    updateLanguage (language) {
      this.currentLanguage = language;
    },
    addQuestion () {
      this.loading = true;
      let questionDto = {
        QuestionTemplateId: Number(this.templateId),
        Question: this.question,
        Language: this.currentLanguage
      };
      this.axios.post('/Installation/AddInstallationQuestion', questionDto)
        .then(() => {
          this.error_clear();
          this.$emit("reloadQuestions");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAllQuestions () {
      this.loading = true;
      this.currentLanguage = null;
      this.axios.get('/Installation/GetQuestions?templateId=' + this.templateId)
        .then((response) => {
          this.questions = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>