<template>
  <div class="editInstallationQuestion">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="question">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="cell">
              <label>{{ $t('language') }}</label>
              <LanguageSelect
                :class="['m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Language') }]"
                :used-languages="usedLanguages"
                :language="currentLanguage"
                @selectedLanguage="updateLanguage"
              />
              <span
                v-show="errors.has('Language')"
                class="badge badge-danger"
              >{{ errors.first('Language') }}</span>
            </div>
          </div>
          <div class="col-12">
            <label>{{ $t('question') }}</label>
            <textarea
              ref="questionArea"
              v-model="question.question"
              v-focus
              :class="['form-control question-area m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Question') || this.$validator.errors.has('Macro') }]"
              type="text"
              rows="8"
            />
            <span
              v-show="errors.has('Question')"
              class="badge badge-danger"
            >{{ errors.first('Question') }}</span>
            <span
              v-show="errors.has('Macro')"
              class="badge badge-danger"
            >{{ errors.first('Macro') }}</span>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="cell">
                <h5>{{ 'Placeholders' }}</h5>
                <h6>{{ $t('common') }}</h6>
                <div class="row">
                  <button
                    class="btn btn-secondary ml-3"
                    @click="addPlaceholder('{installation_name}')"
                  >
                    {{ $t('installationName') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <button
              class="btn btn-primary float-right"
              @click="updateQuestion()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="upload"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js'

export default {
  name: "EditInstallationQuestion",
  components: {
    LanguageSelect: () => import('@/components/SmsManagement/LanguageSelect.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    questionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      question: null,
      usedLanguages: [],
      currentLanguage: null
    }
  },
  watch:{
    questionId: function () {
      this.getQuestion();
    }
  },
  created () {
    this.getQuestion();
  },
  methods: {
    updateLanguage (language) {
      this.currentLanguage = language;
    },
    addPlaceholder (val) {
      if (this.question.question == null) {
          this.question.question = "";
        }
        var questionArea = this.$refs.questionArea;
        var questionPosition = questionArea.selectionStart
        var msg = this.question.question;
        this.question.question = msg.substring(0, questionPosition) + val + msg.substring(questionPosition);
        questionArea.focus();
    },
    getQuestion () {
      this.loading = true;
      this.axios.get(`/Installation/GetQuestion?questionId=${ this.questionId }`)
        .then((response) => {
          this.question = response.data;
          this.currentLanguage = this.question.language;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateQuestion () {
      this.loading = true;
      let payload = {
        id: this.questionId,
        question: this.question.question,
        language: this.currentLanguage,
        questionTemplateId: this.question.questionTemplateId,
      };
      this.axios.put(`/Installation/EditInstallationQuestion`, payload)
        .then(() => {
          this.error_clear();
          this.$emit("reloadQuestions");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
