<template>
  <div class="questionList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(questions)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(questions)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="languageTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span>{{ locales.find(locale => locale.countryCode.toLowerCase() === kgm_getNestedValue(props.field, props.dataItem).toLowerCase()).language }}</span>
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editQuestion(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="() => {
                  indexToDelete = props.dataItem.id;
                  removeQuestion();
                }"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        :show-sidebar="showSidebar"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :template-id="templateId"
          :language="currentLanguage"
          :question-id="currentQuestion"
          @reloadQuestions="reloadQuestionsAndCloseSidebar"
        />
      </Sidebar>
      
      <button
        class="btn btn-secondary mt-4"
        @click="$router.push('/installation-questions/template');"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </button>
    </template>

    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete question?"
      class="overflowHidden"
    >
      <p>Are you sure you want to delete this question?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeQuestion()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

import AddInstallationQuestion from '@/components/Installation/AddInstallationQuestion.vue';
import EditInstallationQuestion from '@/components/Installation/EditInstallationQuestion.vue';
import Sidebar from '@/components/Base/Sidebar.vue';

import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: "InstallationQuestionList",
  components: {
    Sidebar,
    AddInstallationQuestion,
    EditInstallationQuestion,
    SweetModal,
    SweetModalTab
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      questions: null,
      locales: null,
      showSidebar: false,
      currentComponent: null,
      currentLanguage: null,
      currentQuestion: null,
      indexToDelete: null,
      kgm_columns: [
        {
          field: 'question',
          filterable: true,
          filter: 'text',
          title: 'Question'
        },
        {
          field: 'language',
          filterable: true,
          filter: 'text',
          title: 'Language',
          width:'150px',
          cell: 'languageTemplate'
        },
        {
          filterable: false,
          title: 'Options',
          width:'160px',
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  created () {
    this.getData();
  },
  methods: {
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    hideSidebar () {
      this.showSidebar = false;
      this.currentComponent = null;
    },
    showAddQuestion () {
      this.currentComponent = "AddInstallationQuestion";
      this.currentLanguage = "de";
      this.show();
    },
    editQuestion (emitValues) {
      this.currentComponent = "EditInstallationQuestion";
      this.currentQuestion = emitValues.toString();
      this.show();
    },
    //#endregion
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.all([
        this.axios.get('/Installation/GetDefaultLanguages'),
        this.axios.get(`/Installation/GetQuestions?templateId=${ this.templateId }`)
      ]).then(
        this.axios.spread((localesresponse, questionResponse) => {
          if (localesresponse == null) {
            return;
          }
          if (localesresponse.data == null) {
            return;
          }
          this.locales = localesresponse.data;
          
          if (questionResponse == null) {
            return;
          }
          if (questionResponse.data == null) {
            return;
          }
          this.questions = questionResponse.data;
        }
      ))
      .finally(() => {
        this.loading = false;
      });
    },
    removeQuestion () {
      this.loading = true;
      this.axios.delete(`/Installation/RemoveInstallationQuestion?questionId=${ this.indexToDelete }`)
        .then(() => {
          this.getData();
          this.reloadQuestionsAndCloseSidebar();          
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    reloadQuestionsAndCloseSidebar () {
      this.hideSidebar();
      this.getData();
      this.$emit("reloadAuditLogs");
    }
    //#endregion
  }
}
</script>
<style>
.questionList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>